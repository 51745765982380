// NAVIGATION BUTTON FOR DEVICES
var btn_nav = document.querySelector(".btn-nav");
if (btn_nav) {
  btn_nav.addEventListener("click", function (event) {
    event.preventDefault();

    if ("false" == btn_nav.getAttribute("aria-expanded")) {
      btn_nav.setAttribute("aria-expanded", "true");
    } else {
      btn_nav.setAttribute("aria-expanded", "false");
    }
  });
}

var msg_box = document.querySelector("#msg");
if (msg_box) {
  msg_box.addEventListener("click", function (event) {
    msg_box.classList.add("touched");
  });
}

/*
var btn_nav = document.querySelector('.btn-nav');
var accordion_sets = document.querySelectorAll('.accordion .set');

if (btn_nav) {
  btn_nav.addEventListener('click', function (event) {
    event.preventDefault();

    if ('false' == btn_nav.getAttribute('aria-expanded')) {
      btn_nav.setAttribute('aria-expanded', true);
    } else {
      btn_nav.setAttribute('aria-expanded', false);
    }
  });
}

if (accordion_sets.length > 0) {
  [].slice.call(accordion_sets).forEach(function (set) {
    var btn = set.querySelector('[aria-expanded]');
    btn.addEventListener('click', function (event) {
      event.preventDefault();
      if ('false' == btn.getAttribute('aria-expanded')) {
        // this closes any other sets in the accordion and resets the buttons.
        [].slice.call(set.parentElement.children).forEach(function (set_sibling) {
          set_sibling.classList.remove('active');
          set_sibling.style.minHeight = "0px";
          set_sibling.querySelector('[aria-expanded]').setAttribute('aria-expanded', false);
        });
        // activate the clicked set
        btn.setAttribute('aria-expanded', true);
        set.classList.add('active');
        set.style.minHeight = set.getAttribute("data-height");
      } else {
        btn.setAttribute('aria-expanded', false);
        set.classList.remove('active');
        set.style.minHeight = "0px";
      }
    });
  });
}
*/

// SCROLL LOGO
var s_pos = 0;
window.addEventListener("scroll", function (e) {
  var cL = document.getElementById("cornerLogo");
  if (window.scrollY < 100) {
    cL.classList.remove("tucked");
  } else {
    cL.classList.add("tucked");
  }
  s_pos = window.scrollY;
});

document.getElementById("cornerLogo").addEventListener("mouseover", function (e) {
  this.classList.remove("tucked");
});

// ACCORDION+
function accordionInit() {
  var accordion_body = document.querySelectorAll(".accordion .set");
  for (var i = 0; i < accordion_body.length; i++) {
    var accordionBox = accordion_body[i].getBoundingClientRect();
    var btnBox = accordion_body[i].querySelectorAll(".btn")[0].getBoundingClientRect();
    accordion_body[i].setAttribute("data-opn", accordionBox.height + 20 + "px");
    accordion_body[i].setAttribute("data-btn", btnBox.height + "px");
    accordion_body[i].addEventListener("click", function (e) {
      e.preventDefault;
      everyone = document.querySelectorAll(".accordion .set");
      var toggle = false;
      if (this.classList.contains("active")) {
        toggle = true;
      }
      everyone.forEach(function (x) {
        x.classList.remove("active");
        x.style.height = x.getAttribute("data-btn");
      });
      if (toggle == false) {
        this.classList.add("active");
        this.style.height = this.getAttribute("data-opn");
      }
    });
    accordion_body[i].style.height = accordion_body[i].getAttribute("data-btn");
  }
}
setTimeout(function () {
  accordionInit();
}, 200);

// AUTO RESIZE TEXTAREA
var msgBoxer = document.getElementsByTagName("textarea");
var msgBoxerCount = msgBoxer.length;
for (i = 0; i < msgBoxerCount; i++) {
  document.getElementById(msgBoxer[i].id).addEventListener("input", function (e) {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  });
}

// PLACE THE PROJECT TITLE AT BOTTOM OF VISIBLE WINDOW AREA
function rockBottom() {
  var hero_title = document.querySelectorAll(".hero .block-text");
  var hero_image = document.querySelectorAll(".hero .cover img");
  var screenBottom = window.innerHeight;
  if (window.innerWidth > 770) {
    for (var i = 0; i < hero_title.length; i++) {
      var myH = hero_title[i].getBoundingClientRect() + 20;
      hero_title[i].style.marginTop = screenBottom - myH.height + "px";
      hero_image[i].style.height = screenBottom + "px";
    }
  }
}
rockBottom();


var EMPLOYEES = {
  qs_nodes: NodeList,
  qs_array: [],
  active: Element,
  employee_detail: Element,
  init: function () {
    var employees = this;
    employees.qs_nodes = document.querySelectorAll(".employees .employee");
    employees.qs_array = [].slice.call(employees.qs_nodes);
    employees.active = document.querySelector(".employees .employee.active");
    employees.employee_detail = document.querySelector(".employee-detail");

    if (employees.qs_nodes.length > 0) {
      var topThree = 0;
      employees.qs_array.forEach(function (employee) {
        topThree += 1;
        if(topThree<=3){
          employee.addEventListener("click", function (event) {
            event.preventDefault();
            var toggleTime = employee.classList.contains("active");
            if (employees.active) {
              employees.active.classList.remove("active");
              employees.active.parentElement.appendChild(employees.employee_detail);
            }
            //employees.employee_detail.style.height = "80px";
            employees.employee_detail.innerHTML = employee.querySelector(".text").innerHTML;
            if (employees.qs_array.indexOf(employee) == employees.qs_array.length - 1) {
              employee.parentElement.appendChild(employees.employee_detail);
            } else {
              var sibling = employee;
              do {
                sibling = sibling.nextElementSibling;
              } while (sibling && sibling.offsetTop == employee.offsetTop);
              sibling.parentElement.insertBefore(employees.employee_detail, sibling);
            }

            setTimeout(function(){ 
              let EmDetails = document.querySelector('.employee-detail');
              EmDetails.style.height = "auto";
              var EndHeight = EmDetails.offsetHeight
              EmDetails.style.transition = "height 0s";
              EmDetails.style.height = "80px";
              setTimeout(function(){ 
                EmDetails.style.transition = "height 300ms";
                EmDetails.style.height = EndHeight + "px";
              }, 100);
            }, 100);

            if(toggleTime){
              employee.classList.remove("active");
            } else {
              employee.classList.add("active");
            }
            
            employees.active = employee;
            
          });
        }
      });

      window.addEventListener("resize", function () {
        if (!employees.active) {
          return false;
        }
        employees.active.parentElement.appendChild(employees.employee_detail);

        if (employees.qs_array.indexOf(employees.active) == employees.qs_array.length - 1) {
          employees.active.parentElement.appendChild(employees.employee_detail);
        } else {
          var sibling = employees.active;
          do {
            sibling = employees.qs_array[employees.qs_array.indexOf(sibling) + 1];
          } while (sibling && sibling.offsetTop == employees.active.offsetTop);
          sibling.parentElement.insertBefore(employees.employee_detail, sibling);
        }
      });
    }
    var firstEmployee = document.getElementsByClassName('employee')
    if(firstEmployee.length>0 && window.innerWidth > 760){
      document.getElementsByClassName('employee')[0].click();
    }
  }
};

/// SPECIFIC JUST FOR 8 EMPLOYEESS!!!
var AllEmployees = document.querySelectorAll ( '.employee' );
if(AllEmployees.length == 8 ){
  var EmployBox = document.querySelector(".employees");
  EmployBox.innerHTML = EmployBox.innerHTML + '<div class="employee"></div>';
}

EMPLOYEES.init();
